/**
 * The CGI call expects a timestamp. On the confluence page this is described as:
 * 'PolicyMovementUpdateDate returned from the start endpoint', so that is what we
 * use here.
 *
 * (Note that previous MTPet used DateTime.now offset in the opposite direction
 * to the timezone offset, although presumably that was just a bug? It has been
 * noted however that the CGI call can be very fussy on this value, e.g. causing
 * quotes failing to convert properly.)
 *
 * @returns representation of the movement date as a timestamp for the CGI call
 */
export const generateSysTimestamp = (movementDate: string): string => {
  return movementDate.replace(/[.:\-TZ]/g, '');
};

/**
 * The CGI call requires this, however the NWA Tesco journey just used the current date
 * for this, so we do the same.
 * @returns date in the format YYYYMMDD
 */
export const getRenewalDate = (): string => {
  const now = new Date();
  const renewalMonth = (now.getMonth() + 1).toString().padStart(2, '0');
  const renewalDay = now.getDate().toString().padStart(2, '0');
  return `${now.getFullYear()}${renewalMonth}${renewalDay}`;
};
